<template src="./EditGroupModal.html"></template>

<script>
import idiService from "@/services/Idi";
import debounce from "lodash/debounce";
import categoryService from "@/services/Category";

export default {
  name: "edit-group-modal",
  props: {
    openEditModal: {type: Boolean, default: false},
    name: {type: String},
    description: {type: String},
    category: {type: Object},
    coordinator: {type: Object},
    id: {type: String},
    registrationNumber: {type: String},
    institutionName: {type: String},
    institutionId: {type: String}
  },
  data() {
    return {
      categoryList: null,
      coordinatorSelect: null,
      coordinatorSearchExpression: "",
      coordinatorSearch: [],
      dataCoordinator: [],
      isFetching: false,
      selected: this.coordinator ? this.coordinator : {name: null},
      fullName: this.coordinator ? this.coordinator.name + ' ' + this.coordinator.lastName : null,
      coordinatorId: this.coordinator ? this.coordinator.id : '',
    };
  },
  created() {
    this.getInitData();
  },
  mounted() {
    this.selected = this.coordinator ? this.coordinator : {name: null};
    this.fullName = this.coordinator ? this.coordinator.name + ' ' + this.coordinator.lastName : null,
    this.coordinatorId = this.coordinator ? this.coordinator.id : '';
  },
  watch: {
    selected(val) {
      if (val === null) this.coordinatorId = null;
      this.coordinatorId = val.id;
    },
    coordinator(val){
      this.selected = val;
      this.fullName = val.name + ' ' + val.lastName;
      this.coordinatorId = val.id;
    }
  },
  methods: {
    async getInitData() {
      try {
        this.categoryList = await categoryService.getCategoriesWhitSubcategory();
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      this.fullName = null;
      this.$emit("closeTheEditGroupModal");
    },
    closeModalRefresh() {
      this.fullName = null;
      this.$emit("closeTheEditGroupModalAndRefresh");
    },
    customLabel(val) {
      if (val) {
        return val.name + ' ' + val.lastName;
      } else {
        return 'hola';
      }
    },
    async getCoordinatorData(word) {
      try {
        this.dataCoordinator = []
        if (!word) return;
        else {
          this.isFetching = true;
          this.dataCoordinator = await idiService.listCoordinator(word);
        }
      } catch (e) {
        console.error('error', e);
      } finally {
        this.isFetching = false;
      }
    },
    searchExpression: debounce(function (name) {
      if (!name.length) {
        this.coordinatorSearch = null;
        return;
      }
      setTimeout(() => {
        idiService.listCoordinator(this.coordinatorSearchExpression)
          .then(response => {
            this.coordinatorSearch = [];
            response.forEach((item) => this.coordinatorSearch.push(item))
          })
          .catch(error => {
            this.coordinatorSearch = []
            console.error(error)
          })
      });
    }, 300),

    async editGroup() {
      try {
        const obj = {
          name: this.name,
          description: this.description,
          categoryId: this.category.id,
          coordinatorId: this.coordinator.id,
          registrationNumber: this.registrationNumber ? this.registrationNumber : "",
          institutionName: this.institutionName,
          institutionId: this.institutionId
        };
        await idiService.updateGroup(this.id, obj);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se editó el grupo con éxito",
          type: "is-success"
        });
        this.closeModalRefresh();
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger"
        });
        this.closeModal();
      }
    },
    async deleteGroup() {
      try {
        await idiService.deleteGroup(this.id);
        this.$buefy.toast.open({
          duration: 5000,
          position: "is-bottom",
          message: "Se eliminó el grupo con éxito",
          type: "is-success"
        });
        this.closeModalRefresh();
      } catch (error) {
        console.error(error);
        this.$buefy.toast.open({
          duration: 5000,
          message: error.response.data.message,
          position: "is-bottom",
          type: "is-danger"
        });
        this.closeModal();
      }
    }
  },
  computed: {
    disableButton() {
      return this.name && this.description && this.category && this.fullName;
    }
  }
};
</script>

<style lang="scss" scoped src="./EditGroupModal.scss"></style>
